// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import { createSelector } from 'reselect';
import moment from 'moment';
import BigNumber from 'bignumber.js';

export const clickedIdSelector = createSelector(
  (state) => state.chargedBill.detail,
  (detail) => detail
);

export const idSelector = createSelector(
  (state) => state.chargedBill.detail,
  (detail) => {
    const {
      clickedId: { email },
    } = detail;
    return email;
  }
);

export const columnsSelector = createSelector(
  (state) => state.chargedBill.columns,
  (columns) => columns
);

export const detailColumnsSelector = createSelector(
  (state) => state.chargedBill.detailColumns,
  (detailColumns) => detailColumns
);

export const gameTitleSelector = createSelector(
  (state) => state.chargedBill.gameTitle,
  (gameTitle) => gameTitle
);

// 원본 from here
export const OldaccountListSelector = createSelector(
  (state) => state.chargedBill,
  ({ currentBill, rowIndex }) => {
    if (currentBill)
      return currentBill.map((row, i) => {
        // if (index > i)
        return {
          email: row.email,
          id: { id: row.accountId, noDisplay: true },
          companyName: row.companyName,
          chargingPrice: row.chargingPrice.toLocaleString(),
          chargedPrice: row.chargedPrice.toLocaleString(),
          unChargedPrice: row.unChargedPrice.toLocaleString(),
          chargedDate:
            row.chargedDate !== '-'
              ? moment(row.chargedDate).format('YYYY.MM.DD')
              : '-',
          payMethod:
            row.payMethod === 'c'
              ? '카드'
              : row.payMethod === 'v'
              ? '무통장'
              : '기타',
          isPay:
            row.isPay === 'u'
              ? '미청구'
              : row.isPay === 'n'
              ? '미납'
              : row.isPay === 'y'
              ? '완납'
              : row.isPay === 'o'
              ? '연체'
              : null,
        };
      });
  }
);
// 원본 to here

const checkPaymethod = (payMethod) => {
  const isCommaIncluded = payMethod.includes(',');

  const switchFunc = (method) => {
    switch (method) {
      case 'c':
        return '카드';
      case 'v':
        return '가상계좌';
      case 'e':
        return '기타';
      case 'credit':
        return '크레딧';

      default:
        return method;
    }
  };

  if (isCommaIncluded) {
    const splited = payMethod.split(', ');
    let stringPayMethod = '';

    splited.forEach((method) => {
      // console.log('method', method);
      if (stringPayMethod) {
        stringPayMethod = stringPayMethod + '+' + switchFunc(method);
      } else {
        stringPayMethod = switchFunc(method);
      }
    });
    // console.log(stringPayMethod);
    return stringPayMethod;
  }
  return switchFunc(payMethod);
};

const makeAccountList = (currentBill) => {
  if (currentBill)
    return currentBill.map((row, i) => {
      return {
        email: row.email,
        id: { id: row.accountId, noDisplay: true },
        companyName: row.companyName,
        extraBillsTotal: row.extraBillsTotal
          ? row.extraBillsTotal.toLocaleString()
          : '-',
        totalPrice:
          row.totalPrice || row.totalPrice === 0
            ? row.totalPrice.toLocaleString()
            : '-',
        usedCost: row.usedCost ? row.usedCost.toLocaleString() : '-',
        credit:
          row.usedCredit || row.usedCredit === 0
            ? row.usedCredit.toLocaleString()
            : '-',
        surtax: row.surtax ? row.surtax.toLocaleString() : '-',
        chargingPrice:
          row.chargingPrice || row.chargingPrice === 0
            ? row.chargingPrice.toLocaleString()
            : '-',
        chargedPrice:
          row.chargedPrice || row.chargedPrice === 0
            ? row.chargedPrice.toLocaleString()
            : '-',
        unChargedPrice:
          row.unChargedPrice || row.unChargedPrice === 0
            ? row.unChargedPrice.toLocaleString()
            : '-',
        chargedDate:
          row.chargedDate && row.chargedDate !== '-'
            ? moment(row.chargedDate).isBefore(moment('2018', 'YYYY'))
              ? '-'
              : moment(row.chargedDate).format('YYYY.MM.DD')
            : '-',
        payMethod: checkPaymethod(row.payMethod),
        isPay:
          row.isPay === 'u'
            ? '미청구'
            : row.isPay === 'n'
            ? '미납'
            : row.isPay === 'y'
            ? '완납'
            : row.isPay === 'o'
            ? '연체'
            : row.isPay === 'p'
            ? '부분납'
            : null,
      };
    });
};

// export const entireBillSelector = createSelector(
//   state => state.chargedBill.currentBill,
//   currentBill => currentBill
// );

export const accountListSelector = createSelector(
  (state) => state.chargedBill,
  ({ currentBill, rowIndex, isLast, isSorting, getSome }) => {
    // console.log("view(currentBill) 확인", currentBill);
    // console.log("isSorting 확인", isSorting);
    if (!isSorting) {
      if (!isLast) {
        if (currentBill && rowIndex === 1)
          return makeAccountList(currentBill[rowIndex]);
        else if (rowIndex > 1) {
          let resArr = [];
          for (let i = 0; i < rowIndex; i++) {
            resArr = [...resArr, ...currentBill[i + 1]];
          }
          return makeAccountList(resArr);
        }
      } else {
        // isLast === true인 경우
        // rowIndex === 1인 경우 ->
        if (rowIndex === 1) return makeAccountList(currentBill);
        let resArr = [];
        for (let i = 0; i < rowIndex; i++) {
          resArr = [...resArr, ...currentBill[i + 1]];
        }
        return makeAccountList(resArr);
        // console.log(currentBill[rowIndex], "!!!!");
        // return makeAccountList(currentBill[rowIndex]);
      }
    } else {
      // *** 정렬 조건 ***
      // 정렬 후 더보기 클릭 => entireBill에서 그 다음 20개를 추출
      // 정렬된 rows는 currentBill key에 할당되어 있는 상태
      // ...currentBill, ...[entireBill에서 그 다음 20개 배열]
      // 정렬하게 되면 currentBill에 변화를 탐지해서 하단 else if구문이 실행됨...
      if (!isLast) {
        if (currentBill && rowIndex === 1) {
          // 불필요한 분기점?!?!
          console.log('isSorting 에러확인', currentBill);
          return makeAccountList(currentBill);
        } else if (getSome && rowIndex > 1) {
          let resArr = [];
          console.log(rowIndex, 'isSorting getSome 에러확인');
          //   console.log(entireBill, "isSorting getSome 에러확인");
          //   정렬 -> 더보기 클릭 -> 1회에 한해 병합 가능 (rowIndex는 증가하므로 그 다음 더보기 클릭 시에는 누적 병합이 아니고 교체)

          //   resArr = [...currentBill, ...entireBill[rowIndex]];
          //   console.log(resArr, "isSorting getSome 에러확인");
          return makeAccountList(currentBill);
        }
      } else {
        return makeAccountList(currentBill);
      }

      return makeAccountList(currentBill);
    }
  }
);

export const _accountListSelector = createSelector(
  (state) => state.chargedBill,
  ({ currentBill, entireBill, rowIndex, isLast, isSorting, getSome }) => {
    console.log('currentBill', currentBill);
    if (!isSorting) {
      if (!isLast) {
        if (currentBill && currentBill[rowIndex] && rowIndex === 1)
          return currentBill[rowIndex].map((bill) => ({
            ...bill,
            chargedDate:
              bill.chargedDate !== '-'
                ? moment(bill.chargedDate)
                : moment('2000-01', 'YYYY-MM'),
          }));
        else if (rowIndex > 1) {
          let resArr = [];
          for (let i = 0; i < rowIndex; i++) {
            resArr = [...resArr, ...currentBill[i + 1]];
          }
          return resArr.map((bill) => ({
            ...bill,
            chargedDate:
              bill.chargedDate !== '-'
                ? moment(bill.chargedDate)
                : moment('2000-01', 'YYYY-MM'),
          }));
        }
      } else {
        if (rowIndex === 1) return makeAccountList(currentBill);
        let resArr = [];
        for (let i = 0; i < rowIndex; i++) {
          resArr = [...resArr, ...currentBill[i + 1]];
        }
        return resArr.map((bill) => ({
          ...bill,
          chargedDate:
            bill.chargedDate !== '-'
              ? moment(bill.chargedDate)
              : moment('2000-01', 'YYYY-MM'),
        }));
      }
    } else {
      console.log('!!@@', currentBill);
      if (!isLast) {
        if (currentBill && rowIndex === 1) {
          return currentBill.map((bill) => ({
            ...bill,
            chargedDate:
              bill.chargedDate !== '-' ? moment(bill.chargedDate) : '-',
          }));
        } else if (getSome && rowIndex > 1) {
          let resArr = [];
          resArr = [
            ...currentBill.map((bill) => ({
              ...bill,
              chargedDate:
                bill.chargedDate !== '-' ? moment(bill.chargedDate) : '-',
            })),
          ];
          return resArr;
        }
      } else {
        return currentBill.map((bill) => ({
          ...bill,
          chargedDate:
            bill.chargedDate !== '-' ? moment(bill.chargedDate) : '-',
        }));
      }

      return currentBill.map((bill) => ({
        ...bill,
        chargedDate: moment(bill.chargedDate),
      }));
    }
  }
);

export const projectChargeListSelector = createSelector(
  // for VIEW
  (state) => state.chargedBill,
  ({ accountBill, selectedDate }) => {
    const { bills, sumResults } = accountBill;

    let res = {};

    if (bills) {
      const arr = bills.map((bill, i) => {
        const beforeDC = new BigNumber(0);
        const free = new BigNumber(0);
        const chargePrice = new BigNumber(0)
          .plus(bill.base?.fee)
          .plus(bill.push?.fee)
          .plus(bill.function?.fee)
          .plus(bill.db?.fee)
          .plus(bill.chat?.costSum);
        let obj = {
          gameTitle: bill.title,
          /**
           * 게임의 카테고리별 beforeDC를 합산한다
           * base.beforeDC / push.beforeDC / function.beforeDC / db.beforeDC / chat.usedPriceSum
           */
          beforeDC: beforeDC
            .plus(bill.base?.beforeDC)
            .plus(bill.push?.beforeDC)
            .plus(bill.function?.beforeDC)
            .plus(bill.db?.beforeDC)
            .plus(bill.chat?.usedPriceSum),
          freePrice: free
            .plus(bill.base?.free)
            .plus(bill.push?.free)
            .plus(bill.function?.free)
            .plus(bill.db?.free)
            .plus(bill.chat?.freeCostSum),
          chargePrice,
          chargeDetail: [
            {
              [bill.base ? 'base' : null]: {
                beforeDC: bill.base?.beforeDC,
                freePrice: bill.base?.free,
                chargePrice: bill.base?.fee,
                [selectedDate ? 'calculated' : null]:
                  bill.base?.fee * (1 - bill.discountRate),
              },
            },
            {
              [bill.push ? 'push' : null]: {
                beforeDC: bill.push?.beforeDC,
                freePrice: bill.push?.free,
                chargePrice: bill.push?.fee,
                [selectedDate ? 'calculated' : null]:
                  bill.push?.fee * (1 - bill.discountRate),
              },
            },
            {
              [bill.function ? 'function' : null]: {
                beforeDC: bill.function?.beforeDC,
                freePrice: bill.function?.free,
                chargePrice: bill.function?.fee,
                calculated: bill.function?.fee * (1 - bill.discountRate),
              },
            },
            {
              [bill.db ? 'db' : null]: {
                beforeDC: bill.db?.beforeDC,
                freePrice: bill.db?.free,
                chargePrice: bill.db?.fee,
                calculated: bill.db?.fee * (1 - bill.discountRate),
              },
            },
            {
              [bill.chat ? 'chat' : null]: {
                beforeDC: bill.chat?.usedPriceSum,
                freePrice: bill.chat?.freeCostSum,
                chargePrice: bill.chat?.costSum,
                calculated: bill.chat?.costSum * (1 - bill.discountRate) ?? 0,
              },
            },
            {
              [bill.dau ? 'dau' : null]: {
                beforeDC: bill.dau ? bill.dau.beforeDC : null,
                freePrice: bill.dau ? bill.dau.freePrice : null,
                chargePrice: bill.dau ? bill.dau.chargePrice : null,
                calculated: bill.dau
                  ? bill.dau.chargePrice * (1 - bill.discountRate)
                  : null,
              },
            },
            {
              [bill.share ? 'share' : null]: {
                beforeDC: bill.share ? bill.share.beforeDC : null,
                freePrice: bill.share ? bill.share.freePrice : null,
                chargePrice: bill.share ? bill.share.chargePrice : null,
                calculated: bill.share
                  ? bill.share.chargePrice * (1 - bill.discountRate)
                  : null,
                type: bill.share ? bill.share.type : null,
              },
            },
            bill.extra
              ? {
                  extra: bill.extra.results.map((row) => ({
                    details: row.details,
                    inDate: row.inDate,
                    chargePrice: row.chargePrice,
                  })),
                }
              : {},
          ],
          gameId: bill.gameId,
          cost: Number(bill.cost),
          discountRate: bill.discountRate,
          // subTotalCost: bill.subTotalCost,
        };
        obj = {
          ...obj,
          chargeDetail: obj.chargeDetail.map((detail) => {
            if (!detail.null) return detail;
          }),
        };
        return obj;
      });

      res = { arr };
    }
    if (sumResults) {
      res = { ...res, sumResults };
    }
    return res;
  }
);

export const gameBillSelector = createSelector(
  (state) => state.chargedBill.gameBill,
  (gameBill) => gameBill
);

export const gamePPUBillSelector = createSelector(
  (state) => state.chargedBill,
  ({ gamePPUBill, billType, gameTitle, gameId }) => ({
    gamePPUBill,
    billType,
    gameTitle,
    gameId,
  })
);

export const statusSelector = createSelector(
  (state) => state.chargedBill.status,
  (status) => status
);

export const searchKeywordsSelector = createSelector(
  (state) => state.chargedBill.searchKeywords,
  (searchKeywords) => searchKeywords
);

export const dateSearchKeywordsSelector = createSelector(
  (state) => state.chargedBill.searchKeywords,
  (searchKeywords) => {
    const { date } = searchKeywords;
    return date;
  }
);

export const chargingPriceSelector = createSelector(
  (state) => state.chargedBill.chargingPrice,
  (chargingPrice) => chargingPrice
);

export const isErrMsgModalOpenSelector = createSelector(
  (state) => state.chargedBill.isErrMsgModalOpen,
  (isErrMsgModalOpen) => isErrMsgModalOpen
);

export const sortingParamSelector = createSelector(
  (state) => state.chargedBill.sortingParam,
  (sortingParam) => sortingParam
);

export const isLastSelector = createSelector(
  (state) => state.chargedBill.isLast,
  (isLast) => isLast
);

export const rowIndexSelector = createSelector(
  (state) => state.chargedBill.rowIndex,
  (rowIndex) => rowIndex
);

export const manageBillSelector = createSelector(
  (state) => state.chargedBill,
  ({ rowData, manageBillModalOpen }) => ({
    rowData,
    manageBillModalOpen,
  })
);

export const totalAmountSelector = createSelector(
  (state) => state.chargedBill.totalAmount,
  (totalAmount) => totalAmount
);

export const gameIdSelector = createSelector(
  (state) => state.chargedBill.gameId,
  (gameId) => gameId
);

export const gameBillTypeSelector = createSelector(
  (state) => state.chargedBill.gameBillType,
  (gameBillType) => gameBillType
);

export const isPaySelector = createSelector(
  (state) => state.chargedBill.isPay,
  (isPay) => isPay
);

export const isUpdateGameChargingPriceDoneSelector = createSelector(
  (state) => state.chargedBill.isUpdateGameChargingPriceDone,
  (isUpdateGameChargingPriceDone) => isUpdateGameChargingPriceDone
);

/**
export const accountBillSelector = createSelector(
  // 요금 서브 디테일 for VIEW ----> 삭제 할 것
  state => state.currentCharge.accountBill,
  accountBill => {
    const { bills } = accountBill;
    let share = {};
    let dau = {};
    let db = {};
    return bills.map(bill => {
      console.log(bill, "빌 테스트");
      share = {};
      dau = {};
      db = {};
      if (bill.dau) {
        dau = {
          beforeDC: bill.dau.beforeDC,
          freePrice: bill.dau.freePrice,
          chargePrice: bill.dau.chargePrice
        };
      }
      if (bill.share) {
        share = {
          beforeDC: bill.share.beforeDC,
          freePrice: bill.share.freePrice,
          chargePrice: bill.share.chargePrice,
          type: bill.share.type
        };
      }
      if (bill.db) {
        db = {
          beforeDC: bill.db ? bill.db.beforeDC : null,
          freePrice: bill.db ? bill.db.freePrice : null,
          chargePrice: bill.db ? bill.db.chargePrice : null
        };
      }

      const checkingObj = {
        isDauTrue: !!Object.keys(dau).length,
        isShareTrue: !!Object.keys(share).length,
        isDbTrue: !!Object.keys(db).length
      };

      const { isDauTrue, isShareTrue, isDbTrue } = checkingObj;

      const obj = {
        gameTitle: bill.gameTitle,
        [isDauTrue ? "dau" : null]: dau,
        [isShareTrue ? "share" : null]: share,
        [isDbTrue ? "db" : null]: db
      };
      delete obj.null;
      console.log(obj, "객체 재생성");
      return obj;
    });
  }
);
*/
