// Copyright 2013-2023 AFI, Inc. All Rights Reserved.

import { LOCATION_CHANGE } from 'react-router-redux';
import moment from 'moment';

import * as types from './constant';
import BigNumber from 'bignumber.js';
import { isEmpty } from 'lodash';
import chatPrice from '../../../../static/chatPrice.json';

const initialState = {
  selectedDate: {
    year: null,
    month: null,
  },
  currentBill: [],
  columns: [
    {
      label: '아이디',
    },
    {
      label: '회사명',
    },
    {
      label: '기술지원료',
    },
    {
      label: '사용금액',
    },
    {
      label: '이용금액',
    },
    {
      label: '크레딧 차감',
    },
    {
      label: '부가세',
    },
    {
      label: '청구금액',
    },
    {
      label: '납부금액',
    },
    {
      label: '미납금액',
    },
    {
      label: '납부일자',
    },
    {
      label: '납부방법',
    },
    {
      label: '납부상태',
    },
    {
      label: '',
    },
  ],
  detailColumns: [
    {
      label: '이용내역',
    },
    {
      label: '이용금액',
    },
    {
      label: '무료제공',
    },
    {
      label: '합산금액',
    },
    {
      label: '요금할인',
    },
    {
      label: '청구금액',
    },
  ],

  accountBill: {},
  detail: { clickedId: '', chargeDetail: false },
  completeCharge: true,
  sumResults: undefined,
  searchKeywords: {
    date:
      moment().date() >= 5
        ? moment().format('YYYY-MM')
        : moment().subtract(1, 'month').format('YYYY-MM'),
  },
  gameBill: {
    results: [],
    sumResults: {},
  },
  rowData: null,
  manageBillModalOpen: false,
  rowIndex: 1,
  isLast: undefined,
  isSorting: false,
  getSome: false,
  entireBill: [],
};

/**
 * @param {object} chatServer
 * @returns {object}
 * */
const getPlan = (chatServerName) => {
  const [type, maxCount, price] = chatServerName.split('_');

  let plan;

  switch (type) {
    case '1':
      plan = `${'Free'}${maxCount}`;
      break;
    case '2':
      plan = `${'Basic'}${maxCount}`;
      break;
    case '3':
      plan = `${'Business'}${maxCount}`;
      break;

    default:
      break;
  }

  return { plan, price };
};
const gbFromBytes = (traffic) =>
  new BigNumber(traffic)
    .dividedBy(new BigNumber(1024).exponentiatedBy(3))
    .toNumber();

/**
 *
 * @param {obj} param0
 * @returns
 */
const getChatFee = ({
  type = 2,
  maxCount = 3000,
  trafficTotal,
  trafficCost,
  chatServer,
  list: { chatAuth },
}) => {
  const fee = {};
  const ccu = {};
  if (isEmpty(chatServer)) return {};

  Object.entries(chatServer).forEach(([key, value]) => {
    const { plan, price } = getPlan(key);

    fee[`기본 요금 (${plan})`] = {
      callCount: new BigNumber(value.serverRuntime)
        .dividedBy(60)
        .dividedBy(24)
        .decimalPlaces(1, BigNumber.ROUND_DOWN), // 분 -> 일 단위,
      price: parseInt(price, 10),
      usedPrice: value.serverCost,
      cost: value.serverCost,
    };
    ccu[`CCU 확장 (${plan})`] = {
      callCount: new BigNumber(value.ccuRuntime).multipliedBy(60).toNumber(), // 분 -> 초 단위
      price: new BigNumber(value.unitPrice).dividedBy(60).toNumber(),
      usedPrice: value.ccuCost,
      cost: value.ccuCost,
    };
  });

  const getFreeTraffic = () => {
    const { plan } = getPlan(`${type}_${maxCount}`);
    return chatPrice[plan]?.BaseTraffic;
  };

  const callCount = gbFromBytes(trafficTotal);

  const list = {
    ...fee,
    트래픽: {
      callCount,
      limitCount: getFreeTraffic(type, maxCount),
      price: chatPrice.trafficFee,
      usedPrice: new BigNumber(callCount)
        .multipliedBy(chatPrice.trafficFee)
        .decimalPlaces(6, BigNumber.ROUND_DOWN),
      // 이용금액은 FE에서 계산해서 출력하기 (사용량*단가)
      cost: trafficCost, // trafficCost 무료제공량 계산된 최종 금액
    },
    ...ccu,
    gameUser: isEmpty(chatAuth)
      ? {
          callCount: 0,
          cost: 0,
          freeCost: 0,
          limitCount: 50000,
          price: 0.03,
          usedPrice: 0,
        }
      : chatAuth,
  };

  return list;
};

function currentCharge(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return initialState;
    case types.INIT_CHARGED_BILL_LIST:
      return {
        ...state,
        currentBill: initialState.currentBill,
        isLast: initialState.isLast,
        entireBill: initialState.entireBill,
        isLastNum: initialState.isLastNum,
        rowIndex: initialState.rowIndex,
      };
    case types.GET_COLUMNS:
      return {
        ...state,
        columns: action.columns,
      };

    case types.CLICK_CHARGED_BILL_ROW:
      return {
        ...state,
        detail: { clickedId: action.id, chargeDetail: true },
        chargingPrice: action.arr[5].chargingPrice,
        isPay: action.arr[10].isPay,
      };
    case types.GET_BACK_TO_MAIN_TABLE:
      return {
        ...state,
        detail: { clickedId: '', chargeDetail: false },
      };
    case types.HANDLE_OPEN_LAYERD_MODAL:
      return {
        ...state,
        isLayerdModalOpen: true,
      };
    case types.GET_BILL_SUCCESS:
      //   const page = state.rowIndex + 1;

      return {
        ...state,
        currentBill: action.rows,
        entireBill: action.rows,
        isLast: action.isLast,
        isLastNum: action.isLastNum,
        totalAmount: action.totalAmount,
      };

    case types.SORT_ASC_CHARGED_BILL:
      return {
        ...state,
        currentBill: action.rows,
        sortingParam: true,
        isSorting: true,
        getSome: false,
      };

    case types.SORT_DESC_CHARGED_BILL:
      return {
        ...state,
        currentBill: action.rows,
        sortingParam: false,
        isSorting: true,
        getSome: false,
      };

    case types.GET_SOME_OF_BILL:
      // console.log(state.currentBill, 'reducer에서의 currentBill 확인');

      if (state.isSorting) {
        return {
          ...state,
          rowIndex: state.rowIndex + 1,
          isLast: !(state.rowIndex + 1 < state.isLastNum),
          getSome: true,
          currentBill: [
            ...state.currentBill,
            ...state.entireBill[state.rowIndex + 1],
          ],
        };
      }

      return {
        ...state,
        rowIndex: state.rowIndex + 1,
        isLast: !(state.rowIndex + 1 < state.isLastNum),
        getSome: true,
      };

    case types.GET_BILL_ERROR:
      return {
        ...state,
        isErrMsgModalOpen: action.obj,
      };
    case types.GET_ACCOUNT_BILL_SUCCESS:
      return {
        ...state,
        accountBill: action.accountBill,
        selectedDate: {
          year: action.year,
          month: action.month,
        },
        detailColumns: moment('2022-09-01').isBefore(
          `${action.year}-${action.month.toString().padStart(2, '0')}-01`
        )
          ? [
              {
                label: '이용내역',
              },
              {
                label: '이용금액',
              },
              {
                label: '무료제공',
              },
              {
                label: '합산금액',
              },
              {
                label: '요금할인',
              },
              {
                label: '청구금액',
              },
            ]
          : [
              {
                label: '이용내역',
              },
              {
                label: '할인전',
              },
              {
                label: '무료제공',
              },
              {
                label: '이용금액',
              },
            ],
        // TODO: 테스트로 9월 청구서에서도 구버전 포맷 보기
      };
    case types.GET_GAME_BILL_SUCCESS:
      return {
        ...state,
        gameBill: action.gameBill,
        gameTitle: action.gameTitle,
        gameId: action.id,
      };
    case types.GET_GAME_PPU_BILL_SUCCESS:
      return {
        ...state,
        billType: action.billType,
        gamePPUBill:
          action.billType === 'chat'
            ? { ...action.data, list: getChatFee(action.data) }
            : action.data,
        gameTitle: action.gameTitle,
        gameId: action.id,
      };
    case types.GET_GAME_BILL:
      return {
        ...state,
        gameBillType: action.billType,
      };
    case types.UPDATE_GAME_CHARGING_PRICE_SUCCESS:
      return {
        ...state,
        isUpdateGameChargingPriceDone: true,
      };
    case types.GET_SEARCH_KEYWORDS_BILL:
      return {
        ...state,
        searchKeywords: {
          ...state.searchKeywords,
          ...action.obj,
        },
      };
    case types.GET_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        status: action.status,
      };

    case types.HANDLE_MANAGE_BILL_MODAL:
      if (action.id) {
        return {
          ...state,
          rowData: action.value,
          manageBillModalOpen: true,
        };
      } else {
        return {
          ...state,
          rowData: null,
          manageBillModalOpen: false,
        };
      }

    case types.UPDATE_ACCOUNT_BILL_SUCCESS:
      return {
        ...state,
        manageBillModalOpen: false,
      };
    // ******************************
    case types.HANDLE_CLOSE_CHARGE_MODAL:
      return {
        ...state,
        detail: { clickedId: '', chargeDetail: false },
      };

    case types.CONFIRM_CHANGE_STATUS:
      return {
        ...state,
        detail: { clickedId: '', chargeDetail: false },
      };
    case types.SAVE_INPUT:
      return {
        ...state,
        reasonToBeAdded: action.value,
      };

    default:
      return state;
  }
}

export default currentCharge;
